import React from 'react';
import './ArtigoI.css';
import capaDoLivro from '../../../assets/img/capa-do-livro.png'
import Footer from '../../../components/Footer';

const ArtigoI = () => {
  return (

    
 
<div>

<div class="TituloArtigoI">
    <div class="row">
        <div class="col-md-12">
            <h1>Divulgação Científica</h1>
            
        </div>
    </div> </div>
 

    <div className="containerDivulgacao">
      
      <main className="main-contentDivulgacao">
        <h1 className="titleDivulgacao">
          Grupo de pesquisadores brasileiros e europeus lança seu primeiro livro
        </h1>
        <p className="subtitleDivulgacao">Coletânea de artigos aborda as historietas exemplares</p>
        
        <div className="book-details">
          <p>Exemplum y discurso ejemplar en la Península Ibérica  <br></br>
           Hugo Oscar Bizzarri e Leandro Alves Teodoro (Dir.)  <br></br>
           Cultura Acadêmica | 400 páginas </p>
        </div>
        
        <div className="contentDivulgacao">
        <div className="book-image">
        <img src={capaDoLivro} alt="Capa do Livro" style={{ width: '300px', height: 'auto' }} />
        </div>
          <div className="text-contentDivulgacao">
          <p className="highlight-letter">
    No cotidiano atual, recorremos, muitas vezes, a histórias como exemplos para convencer 
    alguém em nossas conversas ou, até mesmo, em alguns casos, para vender algum produto. 
    Também os homens da Idade Média e do limiar da Época Moderna se serviram, com frequência, 
    em suas pregações e em seus livros, de historietas, chamadas <em>exemplum</em> (exemplo) 
    em latim, para persuadir o público a pôr em prática determinados comportamentos de natureza 
    religiosa ou moral. É sobre essa literatura exemplar que trata o recente livro <em>Exemplum 
    y discurso ejemplar en la Península Ibérica</em> ("Exemplum y discurso ejemplar na Península 
    Ibérica"), organizado por Leandro Alves Teodoro, professor de História Medieval na Faculdade 
    de Ciências e Letras da Universidade Estadual Paulista (UNESP), Câmpus de Assis, e por Hugo 
    Oscar Bizzarri, professor de Filologia Hispânica e História da Língua no Departamento de 
    Espanhol da Universidade de Friburgo (Suíça).
  </p>
          </div>
        
        </div>
        <br></br>
        <div className="content-row">
            <div className="main-column">
              <p>
                O livro, que acaba de ser publicado pela Cultura Acadêmica/Editora UNESP, é um dos primeiros
                resultados do grupo de pesquisa do projeto internacional “Instrução e conversão no mundo dos 
                <em> exempla</em>: pilares da moral cristã”, um acordo bilateral entre a Fundação de Amparo à
                Pesquisa do Estado de São Paulo (FAPESP) e o Fundo Nacional Suíço (FNS), coordenado pelos
                dois professores, desde 2021. Ele reúne contributos de pesquisadores brasileiros e europeus
                integrantes do projeto e de outros investigadores convidados, oriundos de diversos campos das
                Ciências Humanas (História, Filologia, Literatura e Antropologia).
              </p>
              <p>
                Como assinalam os organizadores na introdução o objetivo do livro é analisar as funções moralizantes dos <em>exempla</em> (plural de <em>exemplum</em>), refletindo sobre as
                contribuições que esses relatos breves tiveram na fixação de valores e condutas nos diversos
                públicos das terras ibéricas, no medievo e no início do período moderno. Sem entrar nos debates
                conceituais e tipológicos que marcaram os estudos sobre o tema, esses autores dão, como norte
                do trabalho coletivo, as definições de <em>exemplum</em> e de suas variantes encontradas nos
                próprios documentos da época – como o <em>Libro del Tesoro</em>, versão castelhana da obra de
                Brunetto Latini, do século XIII, e o <em>Universal Vocabulario</em>, de Alfonso de Palencia, do
                século XV: “Se ‘exemplar’ se tratava de uma ação recomendável e virtuosa, possível de admirar, o
                ‘exemplo’ era a exemplificação dessa prática mediante um feito que conduziria ao caminho
                correto”. De um modo geral, o <em>exemplum</em> é entendido por eles como um recurso retórico,
                dependente de um discurso mais amplo e indispensável para ilustrar uma mensagem clara,
                cativante e convincente.
              </p>
               <p>
               A coletânea tem ao todo onze capítulos escritos em espanhol, distribuídos em três partes
               <p>
     - <em>La escritura sobre la ejemplaridad </em>(“A escrita sobre a exemplaridade”), 
      <em>Personajes ejemplares</em> (“Personagens exemplares”) e 
      <em> Las virtudes y los pecados en los exempla</em> (“As virtudes e os pecados nos exempla”).
    </p>
               </p>
            </div>
            <div className="quote-column">
              <blockquote>
                <p className='citacaoartigo'>
                  “Não se trata, portanto, de examinar a classificação dos <em>exempla</em>, tarefa que tem sido
                  abordada por uma série de livros de historiadores e filólogos nas últimas décadas, senão os
                  comportamentos sugeridos por seus personagens e a maneira que ensinavam a prática de
                  virtudes”
                </p>
                <footer>
                  <br></br>
                  <p>Introdução, p. 11.</p>
                </footer>
              </blockquote>
            </div>
          </div>
          <div className="containerDivulgacao">
         
  </div>

  <div className="content-row">
  <div className="main-column">
  <br></br>
  <div className="section-title">
        <h2>ESCRITA E EXEMPLARIDADE</h2>
      </div>

  <br></br>
    <div className="contentDivulgacao">
  <div className="quote-column">
    <blockquote>
      <p className='citacaoartigoEsquerda'>
        “Nos sermões medievais, os <em>exempla</em> eram antes ‘ícones verbais’, que ofereciam imagens em movimento de comportamentos que deviam ser seguidos ou evitados.”
      </p>
      <footer>
        <p>Jacques Berlioz, p. 62.</p>
      </footer>
    </blockquote>
  </div>
  <div className="text-column">
    <p>
      A primeira parte do livro, <em>La escritura sobre la ejemplaridad</em> (“A escrita sobre a exemplaridade”), concentra os estudos de autores já consagrados nas pesquisas sobre a literatura exemplar. No capítulo de abertura, <em>Juan Ruiz Moralista</em>, Hugo Oscar Bizzarri se debruçam sobre as moralizações no <em>Libro de buen Amor</em>, aspecto, até então, negligenciado pelos estudiosos da obra, evidenciando suas relações com os <em>exempla</em> que as precedem, bem como as formas particulares de construção do discurso moralizante do clérigo. Em seguida, em <em>Narrativa ejemplar y ejemplaridad en la Edad Media:</em>
      <em> ¿Antagonismo o complementariedad? El ejemplo de la Vida de San Luis de Jean de Joinville</em> (“Narrativa exemplar e exemplaridade na Idade Média: Antagonismo ou complementaridade? O exemplo da <em>Vida de São Luís</em> de Jean de Joinville”), Jacques Berlioz, diretor de pesquisa do Centro Nacional da Pesquisa Científica (CNRS – Paris), discute o caráter exemplar dos relatos construídos por Jean de Joinville (1224-1317) em sua <em>Vida de São Luís</em>, revelando a moral política presente em alguns deles.
      </p>
     <p> No terceiro capítulo, <em>La pervivencia de los exempla medievales en el siglo XVI: el Exemplario de la sancta fee cathólica</em> (“A sobrevivência dos <em>exempla</em> medievais no século XVI: o <em>Exemplario de la sancta fee cathólica</em>”),
      María Jesús Lacarra, professora titular de Literatura Espanhola na Universidade de Zaragoza (Espanha), nos dá a conhecer um manual de doutrina pouco estudado, impresso no século XVI, o<em> Exemplario de la sancta fee cathólica,</em> que incorpora <em>exempla</em> medievais em período moderno.      
    </p>
  </div>
  </div>

  </div> </div>
 

 
<div className="text-and-quote">
 
    <p className='pnormal'>Por fim, no capítulo que fecha a primeira parte, <em>Ejemplaridad y sátira en las Novelas de Ginés Carrillo Cerón. Tres ejemplos singulares</em> (“Exemplaridade e sátira nas Novelas de Ginés Carrillo Cerón. Três exemplos singulares”), Abraham Madroñal, professor de Literatura Espanhola na seção de espanhol do Departamento de Línguas e Literaturas Românicas da Universidade de Genebra (Suíça), mostra que Ginés Carrillo Cerón (c.1580-1639), ao dar continuidade ao <em>Coloquio de los perros</em>, novela curta de Miguel de Cervantes (1547-1616), optou por oferecer modelos menos satíricos e mais exemplares.
    </p>
   
   
 </div>
 

  
 

<div className="contentDivulgacao">
  <div className="containerDivulgacao">
    
    <div className="section-title">
        <h2>PERSONAGENS EXEMPLARES</h2>
    </div>
    <div>
      <p>A segunda parte do livro, <em>Personajes ejemplares</em>, tem como fio condutor as figuras tidas como dignas de imitação ou de evitação nas narrativas exemplares. No primeiro capítulo, <em>Orden y Género: Representaciones de San Francisco de Asís en los primeros manuscritos (1229-1246)</em> (“Ordem e Gênero: Representações de São Francisco de Assis nos primeiros manuscritos, 1229-1246”), Ana Paula Tavares Magalhães Tacconi, professora de História Medieval na Faculdade de Filosofia, Letras e Ciências Humanas da Universidade de São Paulo (USP), com base nas primeiras vidas e legendas (escritos de santos) de Francisco de Assis (c.1181-1226), discorre sobre o processo de construção de um “Francisco Cavaleiro” – e, posteriormente, de um<em> miles Christi</em> (cavaleiro de Cristo) –, em detrimento de um “Francisco burguês”, e seus desdobramentos.</p>
    </div>
    <div >
      <p>No capítulo seguinte, <em>Aníbal Barca en el entramado de figuras ejemplares bajomedievales</em> (“Aníbal Barca no emaranhado de figuras exemplares baixo-medievais”), Adrián Fernández González, doutor em Línguas e Literaturas Românicas e Ibéricas da Universidade de Friburgo (Suíça), analisa os mecanismos de construção da exemplaridade do general cartaginês Aníbal Barca (séc. III-II a.C.), um dos protagonistas da segunda guerra púnica, nos exempla antigos da literatura ibérica do século XV, ressaltando a importância da rede exemplar na qual se integram. Ainda no campo do <em>exemplum</em> histórico, o capítulo <em>El suicidio de Catón: un <em>exemplum</em> ambivalente</em> (“O suicídio de Catão: um <em>exemplum</em> ambivalente”), de Georgina Olivetto, professora adjunta de Literatura Espanhola I (Medieval) na Universidade de Buenos Aires (Argentina), traz a discussão das moralizações contrapostas do <em>exemplum</em> do suicídio de Catão (95-46 a.C.), nos autores do século XV castelhano, destacando as suas relações com seus âmbitos de produção e recepção.</p>
    </div>
     
  </div>
</div>
     
 


 
  <div className="containerDivulgacao">
     
     <div className="section-title">
         <h2>VIRTUDES E PECADOS</h2>
       </div>
       <div >
       <p>A terceira e última parte do livro, <em>Las virtudes y los pecados en los exempla</em>, põe em relevo os modelos de conduta desejáveis e repreensíveis encontrados nesses relatos breves 
        No primeiro capítulo, <em>La corrección de la lengua en exempla de la Confessio Amantis y del Horto do Esposo</em> (“A correção da língua em <em>exempla da Confessio Amantis e do Horto do Esposo”</em>), Leandro Alves Teodoro examina os usos virtuosos e condenáveis da língua, entre os quais, o binômio oração/ detração, veiculados nos <em>exempla</em> de duas obras que circularam em solo ibérico quatrocentista.
       Ainda no terreno dos pecados da língua, ou seja, os pecados relativos ao uso da palavra, o próximo capítulo, <em>Falso testimonio y difamación en tres exempla hagiográficos de Clemente Sánchez</em> (“Falso testemunho e difamação em três exempla hagiográficos de Clemente Sánchez”) (“Falso testemunho e difamação em três <em>exempla</em> hagiográficos de Clemente Sánchez”), de María Luzdivina Cuesta Torre, professora de Filologia Hispânica na Faculdade de Filosofia e Letras da Universidade de León (Espanha), contém a análise do falso testemunho e da difamação em três relatos breves, de tipo hagiográfico, ressaltando as conexões entre a prática e a teoria em obras de gêneros distintos de Clemente Sánchez (1365-c.1438), entre outros aspectos.
       </p>
       <article  >
      <p>No terceiro capítulo, <em>Virtudes, moral y vicios: La enseñanza en el Valerio de las historias a partir del exemplum</em> (“Virtudes, moral e vícios: O ensino em Valerio de las historias a partir do <em>exemplum”</em>), Laura Lecina Nogués, doutoranda no Programa de Literaturas Hispânicas da Universidade de Zaragoza (Espanha), investiga os distintos tipos de <em>exempla</em> que compõem a obra <em>Valerio de las historias,</em> destinados ao ensino das elites, bem como a técnica de composição de seu autor, Diego Rodríguez de Almela (1426-1489). Finalmente, no capítulo de encerramento da coletânea, <em>Simbolismo, función y moralización de los insectos en algunos ejemplarios medievales (Castilla siglos XIII-XV)</em> (“Simbolismo, função e moralização dos insetos em alguns exemplários medievais, Castela séculos XIII-XV”), Clara Zünzler, doutoranda do Departamento de Espanhol da Universidade de Friburgo (Suíça), aborda os insetos em algumas coleções de <em>exempla</em>, de tradições distintas, destacando o simbolismo, o significado e o uso desses animais nos relatos.</p>
    </article>
    <article  >
      <p>Vale ainda dizer que não passa despercebida a variedade de tipos de <em>exemplum</em> (hagiográfico, bíblico, histórico, fábula etc.) abarcada no empreendimento coletivo, como também o esforço dos autores de compreender as narrativas exemplares com base nas suas especificidades e contextos, buscando, assim, entender as visões de mundo das gentes da Idade Média e do início da Época Moderna. Para concluir, a leitura em espanhol poderá causar algum grau de estranhamento, mas não deve ser empecilho ao leitor. Ao contrário, ela possibilita que o público, tanto especializado como leigo, tenha acesso aos estudos mais recentes sobre o assunto, já que muitas publicações sobre os <em>exempla</em> encontram-se em outras línguas. O livro está disponível para <em>download</em> gratuito no <em>site</em> da editora (<a href="https://www.culturaacademica.com.br" target="_blank" rel="noopener noreferrer">https://www.culturaacademica.com.br</a>).</p>
    </article>
      <p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>
    </div>
  </div>
 
<div>
                            <div className="pdf-tbn-evento">
                                <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/Resenha_Exemplum.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>
                              
 
                            </div>

                        </div>
    
        <footer className="footerDivulgacao">
          <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
          <p>Agosto de 2024</p>
        </footer>
      </main>
    </div>

    <Footer/>
    </div>
  );
}

export default ArtigoI;
