import React from 'react';
import './ArtigoI.css';
import capaArtigoII from '../../../assets/img/capa-do-artigoII.png'
import imgArtigoII from '../../../assets/img/imgArtigoII.jpeg'

import Footer from '../../../components/Footer';

const ArtigoII = () => {
  return (
    <div>

    <div class="TituloArtigoI">
        <div class="row">
         <div class="col-md-12">
            <h1>Divulgação Científica</h1> 
        </div>
     </div>  
    </div> 

    <div className="containerDivulgacao">
    <div className="book-image">
        <img src={capaArtigoII} alt="Capa do Livro" 
            style={{ width: '100%', maxWidth: '800px', height: 'auto' }} 
         />
        </div>
      <main className="main-contentDivulgacao">
        <h1 className="titleDivulgacao">
         </h1>
        <p className="subtitleDivulgacao"> </p>
         
         
        <div className="text-contentDivulgacao">
          <p className="highlight-letter">
          
          "Uma vez pregando um frade sobre o nome de Jesus muitas boas coisas, estava aí um cidadão que havia enfermidade de febre. E quando ouviu a virtude deste nome, escreveu o nome Jesus na água e bebeu-a no tempo que lhe haveria de vir a sezão e logo o deixou a febre”.
  </p>   
  <p className="paragraph-content ">
  O trecho acima, da obra anônima portuguesa <em>Horto do Esposo</em>, composta na passagem do século XIV para o XV, é uma entre as várias historietas exemplares estudadas pelo grupo de pesquisadores brasileiros e europeus do projeto “Instrução e conversão no mundo dos <em>exempla:</em> pilares da moral cristã”, coordenado pelo historiador Leandro Alves Teodoro, professor de História Medieval na Faculdade de Ciências e Letras da Universidade Estadual Paulista (UNESP), Câmpus de Assis, e pelo filólogo Hugo Oscar Bizzarri, professor de Filologia Hispânica e História da Língua no Departamento de Espanhol da Universidade de Friburgo (Suíça).
         </p> 
         
         <p className="paragraph-content ">
         O projeto, que teve início em 2021, resulta de um acordo bilateral entre a Fundação de Amparo à Pesquisa do Estado de São Paulo 
         (FAPESP) e o Fundo Nacional Suíço (FNS). Também fazem parte dele Ana Paula Tavares Magalhães Tacconi,
          professora de História Medieval na Faculdade de Filosofia, Letras e Ciências Humanas da Universidade de São Paulo (USP); 
          Bruno Guilherme Feitler, professor de História Moderna na Escola de Filosofia, Letras e Ciências Humanas da Universidade Federal 
          de São Paulo (Unifesp); María Luzdivina Cuesta Torre, professora de Filologia Hispânica na Faculdade de Filosofia e Letras da
           Universidade de León (Espanha); Abraham Madroñal Durán, professor de Literatura Espanhola no Departamento de Línguas e 
           Literaturas Românicas da Universidade de Genebra (Suíça); e Isabel Maria de Barros Dias, professora no Departamento de 
           Humanidades da Universidade Aberta (Portugal), além de bolsistas de Iniciação Científica, Doutorado, Pós-Doutorado e de
           Treinamento Técnico.

         </p>
         <p className="paragraph-content ">
         O <em>exemplum</em> (exemplo; plural <em>exempla</em>: exemplos), termo em latim, utilizado pelos medievais para se referirem
          a essas historietas ou relatos breves – podendo, ainda, compreender as fábulas, as anedotas e as parábolas,
           entre outros tipos –, teve origem na Antiguidade, mas foi durante a Idade Média que a forma literária ganhou 
           pleno vigor. Extraídos de fontes cristãs, orientais ou pagãs, do mundo clássico e do próprio medievo, 
           esses pequenos relatos foram inseridos em pregações e 
         em obras morais para persuadir o público a pôr em prática algum ensinamento religioso, moral ou doutrinal.
         </p>
         
         <p className="paragraph-content ">
         Apesar de diversos estudos dedicados ao <em>exemplum</em> medieval – desde os fins do século XIX –, 
         estamos longe de um consenso sobre o seu conceito ou tipologia. 
         “Dado o escasso determinismo formal do exemplo e por tratar-se de um tipo de composição que se relaciona com tantas outras, 
         não houve uma única maneira de estabelecer e descrever sua tipologia”, afirma Bizzarri na 
         introdução de <em>Cuentos latinos de la Edad Media</em> (Gredos, 2006). Todavia, essas historietas apresentam alguns 
         elementos marcantes, dos quais se destacam a <em>brevidade</em> – o caráter breve deve-se muito mais ao gosto estético 
         medieval do que a uma limitação criativa; o <em>didatismo</em> – esses relatos têm o propósito fundamental de instruir,
          contudo é possível encontrar vários formatos de <em>didatismo</em>, implícitos e explícitos; a 
          dependência – os exemplos servem de apoio a uma exposição, não tendo, portanto, autonomia por si mesmos; e, por fim, a <em>persuasão</em> – há uma intenção em convencer ou converter aqueles que leem ou ouvem essas pequenas histórias exemplares.
         </p>




         <div className="section-title">
         <h2>OS <em>EXEMPLA</em> EM TERRAS IBÉRICAS</h2>
         </div>
 
         <div className="content-row">
            <div className="main-column">
              <p className='paragraph-normal'>
              Os pesquisadores do projeto internacional têm como foco de estudo examinar as funções 
              moralizantes dos relatos breves contidos em documentos da península Ibérica, 
              entre os séculos XIII ao XVI. Eles pretendem evidenciar como, no processo de aprendizagem 
              da moral cristã, essas historietas contribuíram para a memorização de valores e comportamentos 
              tidos como virtuosos ou pecaminosos para a época, atentando-se, também, para a 
              sensibilização que elas promoviam por meio dos sentidos, como a visão e a audição. 
              “O estudo sobre os <em>exempla</em> mostra a importância da imagem para a fixação de doutrinas e 
              ensinamentos. Num universo de poucas pessoas letradas, a leitura pública dessas narrativas 
              facilitava a compreensão de uma lição e ajudava homens e mulheres a 
              memorizarem tipos de virtudes a serem repetidas ou de pecados a serem evitados”, diz Teodoro.
              </p>
              
              <p className='paragraph-normal'>
               A escolha pelas historietas exemplares ibéricas deve-se ao fato de a região ter iniciado a produção dessa literatura em 
               língua vernácula. “Os mundos ibéricos foram pioneiros na produção de <em>exempla</em>, 
               destacando-se, por exemplo, a <em>Disciplina clericalis</em> de Pedro Alfonso de Huesca. 
               Além disso, em razão da presença de uma cultura árabe na península Ibérica, 
               alguns contos orientais foram introduzidos nessas plagas e, depois, transmitidos às demais terras ocidentais”, 
               acrescenta o historiador. Por outro lado, a familiaridade com a documentação ibérica com base na experiência e no
                desenvolvimento do auxílio FAPESP Jovem Pesquisador “O ensino da fé cristã na península Ibérica (séculos XIV, XV e XVI)”,
coordenado pelo professor Leandro Alves Teodoro, possibilitou a aproximação com os estudos da literatura exemplar da Idade Média e do início da Época Moderna.
               </p>
            
               <p className='paragraph-normal'>

            Os documentos que contêm essas narrativas breves utilizadas pelos estudiosos do projeto contemplam obras escritas, 
            compiladas ou traduzidas, sobretudo, nos reinos de Castela e Portugal, entre os séculos XIII e XVI. 
            Trata-se de textos de diversos gêneros, como os escritos destinados à corte e à nobreza 
            (conhecidos como “espelhos de príncipes”); as coletâneas de <em>exempla</em> e os sermões usados, 
            principalmente, nas pregações; os tratados devocionais voltados para leituras individuais e coletivas;
             entre outros. Fazem parte dessa documentação, mas não só, as obras de origem oriental, como 
             <em> Calila e Dimna, Sendebar, Barlaam e Josafat,</em> e as propriamente castelhanas e portuguesas, como <em>Libro de buen amor, Castigos e documentos del rey don Sancho IV</em> e <em>Horto do Esposo.</em>
            </p>




            </div>
            <div className="quote-column">
              <blockquote>
                <p className='citacaoartigo'>
                “O estudo sobre os <em>exempla</em> mostra a importância da imagem para a fixação de doutrinas e ensinamentos. Num universo de poucas pessoas letradas, a leitura pública dessas narrativas facilitava a compreensão de uma lição e ajudava homens e mulheres a memorizarem tipos de virtudes a serem repetidas ou de pecados
                a serem evitados”.
                </p>
                
              </blockquote>
            </div>
            </div>


            <p className="paragraph-content ">
            Algumas conclusões chegadas pelos pesquisadores do grupo, até o momento, publicadas na obra 
            coletiva <em>Exemplum y discurso ejemplar en la Península Ibérica, siglos XIII-XVII</em> (Cultura Acadêmica, 2024), mostram alguns aspectos interessantes quanto aos métodos adotados pelos
              escritores que utilizaram os relatos breves em suas obras e a certas condutas desejadas e condenadas naquele período.
            </p>

            <p className="paragraph-content ">
            Ao observar como Juan Ruiz (c.1284-c.1351), conhecido como Arcipreste de Hita, introduz as suas moralizações 
            (comentários de natureza moral e doutrinal) dos <em>exempla</em>, em seu <em>Libro de buen amor</em>, composto no século XIV,
             Hugo Bizzarri percebeu que o clérigo desenvolve, cuidadosamente, as moralizações oferecidas por suas fontes, 
             mas as apresenta de formas diferentes. A opção por mantê-las simples, a fim de não ofuscar os seus relatos, e 
             a inserção de conclusões moralizantes na boca de uma personagem são algumas delas. 
             “Cada autor tem uma mensagem a transmitir e sua forma particular de construir seu discurso moralizante”, constata Bizzarri. 
             É o caso também, séculos mais tarde, de Ginés Carrillo Cerón, secretário da Real Chancelaria de Granada estudado por 
             Abraham Madroñal, que adota modelos mais exemplares e “cristianizados” na continuidade que deu ao <em>Coloquio de los perros</em>, novela curta de Miguel de Cervantes
             (1547-1616), em seu volume miscelâneo <em>Novelas de varios sucesos,</em> de 1635.
            </p>
            <p className="paragraph-content ">
            As formas virtuosas do uso da língua, ou seja, das palavras, fazem parte do rol de ensinamentos dos <em>exempla</em> ibéricos. 
            Na Idade Média, o órgão era visto de forma paradoxal, pois os cristãos poderiam tanto fazer um bom uso das palavras 
            (orar, confessar, louvar etc.) como um mau uso delas (mentir, difamar, caluniar, blasfemar etc.). Ao examinar alguns <em>exempla</em> de obras como a <em>Confessio Amantis,</em> escrita no século XIV por John Grower (c.1325-1403), 
            e a já mencionada <em>Horto do Esposo</em>, Teodoro constatou, entre outros pontos, que os usos virtuosos das palavras 
            são estimulados, em alguns deles, com base em exemplos sobre os pecados da língua. 
            É o caso do <em>exemplum</em> sobre Santa Constança, filha de Constantino, primeiro imperador romano cristão, 
            utilizado por Grower. “A vida de Constança salienta a diferença entre oração e detração, e a fé como ação superior à 
            inveja e ao perjúrio; criando um jogo de oposição em que a encenação do pecado ajudava não apenas a 
            repudiar a sua prática, mas também a seduzir o leitor pelo seu contrário, no exercício da virtude”.
                </p>
                <p className="paragraph-content ">
                Além disso, a leitura dessas narrativas breves torna-se mais enriquecida quando é possível comparar as obras teóricas de
                 um mesmo autor com os <em>exempla</em> recolhidos por ele. Foi o que concluiu María Luzdivina Cuesta Torre, ao estudar três 
                 relatos sobre santos vítimas de falso testemunho e de difamação no <em>Libro de los exemplos por A.B.C.</em>, coletânea de <em>exempla</em> do século XV, de Clemente Sánchez de Vercial (1365- c.1438), autor também de <em>Sacramental</em>, obra pastoral 
                 concluída em 1423, voltada para uso dos confessores. À luz da doutrina sobre a mentira e dos pecados ligados a ela no 
                 tratado doutrinal do autor, Cuesta Torre pôde compreender melhor os ensinamentos veiculados às historietas, os quais diferenciam as
                  espécies de pecados ligados à língua. Os <em>exempla</em>, portanto, entre outros aspectos, ajudavam os cristãos a identificarem os tipos de pecados e a sua gravidade.
                    </p>


                    <div className="contentDivulgacao">  
        <div className="book-image">
        <img src={imgArtigoII} alt="Capa do Livro" style={{ width: '600px', height: 'auto' }} />   
        </div>
          <div className="text-contentDivulgacao">
          <p>Figura 2. alguns pesquisadores do projeto no III congresso Internacional, ocorrido
na UNESP – câmpus de assis, em junho de 2024. 
começando pela esquerda, abraham Madroñal, Isabel Maria de Barros Dias, 
Hugo Bizzarri, Bruno Feitler, María luzdivina cuesta torre e leandro alves teodoro.</p>

</div> </div>
 <br></br>
                    <div className="section-title">
         <h2>EVENTOS E PUBLICAÇÕES</h2>
          

         <p className='paragraph-normal'>
         O grupo internacional já realizou três eventos científicos dedicados ao
 tema do <em>exemplum</em>. O primeiro, <em>Ética e formação moral do exemplum,</em> ocorreu em 2022, no Instituto de Filosofia e Ciências Humanas da Universidade 
  Estadual de Campinas (Unicamp); o segundo,<em> Exemplum y discurso ejemplar en la Península Ibérica (siglos XIII-XVII),</em> aconteceu em junho de 2023, na Universidade de Friburgo (Suíça); por fim, o terceiro evento, <em>A Instrução e a persuasão dos exempla,</em> foi realizado em junho de 2024, no Departamento de História, da 
  UNESP – Câmpus de Assis. Também nesse último ano, além das publicações individuais, foi lançada, pela 
  Cultura Acadêmica/Editora UNESP, a primeira obra coletiva do núcleo, <em>Exemplum y discurso ejemplar en la Península Ibérica (siglos XIII-XVII),</em>
   organizada pelos professores Leandro Alves Teodoro e Hugo Oscar Bizzarri, a qual reúne estudos de 
   pesquisadores do projeto e de outros autores de renome no assunto. As conferências do 
   primeiro e do terceiro eventos podem ser acessadas, respectivamente, no canal do <em>Youtube</em> do IFCH da Unicamp 
   (www.youtube.com/@ifchunicamp1) e no <em>site</em> do grupo (https://umahistoriadapeninsula.com/). 
   O <em>download</em> do livro pode ser feito na página da editora (https:// www.culturaacademica.com.br).

</p>
         </div>
         <div className="content-columns-artigoII">
  <div className="column-artigoII">
    <h3 id='h3-artigoII'>Projetos</h3>
    <p id='p-artigoII'>
      » Instrução e conversão no mundo dos <em>exempla</em>: pilares da moral cristã 
      (FAPESP n. 21/02936-0). Modalidade: Convênio. Pesquisador responsável: 
      Leandro Alves Teodoro (Unesp-Assis). Pesquisar responsável no exterior: 
      Hugo Oscar Bizzarri (Universidade de Friburgo – Suíça).
    </p>

    <p>
    » O ensino da fé cristã na península Ibérica, sécs. XIV, XV e XVI 
    (FAPESP n. 1711111-9). Modalidade: Jovem Pesquisador. Pesquisador 
    responsável: Leandro Alves Teodoro (Unesp-Assis).
    </p>
  </div>
  <div className="column-artigoII">
    <h3 id='h3-artigoII'>Livros consultados</h3>
    <p id='p-artigoII'>
      » <em>Cuentos latinos de la Edad Media.</em>
       Introducción, traducción y notas de Hugo O. Bizzarri. Madrid: Gredos, 2006.
       <p id='p-artigoII'>
       »  BIZZARRI, Hugo Oscar; TEODORO, Leandro Alves (Dir.).
        O exemplum y discurso ejemplar en la Península Ibérica. 
        São Paulo: Cultura Acadêmica, 2024.
</p>
 
    </p>
    <p>  <strong>  Patrícia Serieiro.</strong> Bolsista de Jornalismo Científico-IV/ FAPESP (Processo 2024/01457-9).</p>

  </div>
</div>




         </div>





         
    <div className="pdf-tbn-evento">
            <a href="https://bucket.server.umahistoriadapeninsula.com/artigos/Materia-Setembro.pdf" target="_blank" class="btn btn-main mt-20">Baixar Arquivo</a>                      
     </div>








     <footer className="footerDivulgacao">
          <p>O ENSINO DA FÉ CRISTÃ NA PENÍNSULA IBÉRICA (SÉCS. XIV, XV E XVI)</p>
          <p>Setembro de 2024</p>
        </footer>

     </main> 
     
     </div>   
    <Footer/>
    </div>
  );
}

export default ArtigoII;
